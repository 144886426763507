<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 pt-24 pb-16">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="TOTAL SLP"
              :statTitle="total"
              statPercentColor="text-emerald-500"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
              statDescripiron="Total SLP"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="INGAME SLP"
              :statTitle="total"
              statPercentColor="text-red-500"
              statDescripiron="Current InGame SLP"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="SLP Price"
              :statTitle="this.slpprice"
              statPercentColor="text-red-500"
              statDescripiron="Current SLP Price"
              statIconName="fas fa-dollar-sign"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="AXS Price"
              :statTitle="this.axs"
              statPercentColor="text-red-500"
              statDescripiron="Current AXS Price"
              statIconName="fas fa-ruble-sign"
              statIconColor="bg-orange-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      totalslp: 0,
      ingame: 0,
      slpprice: 0,
      axs: 0,
    };
  },
  props: ["total"],

  components: {
    CardStats,
  },
  methods: {
    getSLP() {
      // You can use `let` instead of `const` if you like
      axios
        .get(
          "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25&vs_currencies=php"
        )
        .then((response) => {
          this.slpprice =
            response.data["0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25"]["php"];
        });
    },
    getAXS() {
      // You can use `let` instead of `const` if you like
      axios
        .get(
          "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xbb0e17ef65f82ab018d8edd776e8dd940327b28b&vs_currencies=php"
        )
        .then((response) => {
          this.axs =
            response.data["0xbb0e17ef65f82ab018d8edd776e8dd940327b28b"]["php"];
        });
    },
  },
  created: function() {
    // this.getUsers();
    this.getSLP();
    this.getAXS();
  },
};
</script>
