<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <component-two :total="this.totalslp"> </component-two>

    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            axie.com.ph
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        ></div>
      </div>
    </div>

    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Scholar #
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Team Name
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Average Daily
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              MMR
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Current Daily
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Total SLP
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Next Claim
            </th>
          </tr>
        </thead>

        <tbody v-for="item in users" v-bind:key="item">
          <tr>
            <!-- <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex">
                <img
                  v-for="item in item.axies"
                  v-bind:key="item"
                  :src="item"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                />
              </div>
            </td> -->
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              <a target="_blank">
                {{ item.index }}
              </a>
            </th>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              <a v-bind:href="item.address" target="_blank">
                {{ item.name }}
              </a>
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <!-- {{ dateTime(item.slp.lastClaimedItemAt) }} -->
              {{
                Math.floor(
                  item.slp.total / checkave(item.slp.lastClaimedItemAt)
                )
              }}
            </td>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              {{ item.leaderboard.elo }}
            </th>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              {{ item.slp.todaySoFar }}
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ item.slp.total }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ dateTime(item.slp.lastClaimedItemAt) }}
              <!-- {{ checkave(item.slp.lastClaimedItemAt) }} -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ComponentTwo from "../Headers/HeaderStats.vue";

export default {
  name: "Card",
  components: {
    ComponentTwo,
  },
  data() {
    return {
      users: [],
      totalslp: 0,
      ingame: 0,
      ronin: [
        {
          name: "Aya",
          value: "0xef34a81d1829251606941ca11e516afca3d9b564",
        },
        {
          name: "Peter",
          value: "0x5aa4df9bf61f86bf324b737fbf09aecba9c31caa",
        },
        {
          name: "Jon",
          value: "0x31c4e647ae96ecd606403bea0ef19bb465490aa8",
        },

        {
          name: "Angelo",
          value: "0x7acb5bed064c07ca17334519c492daf3426b9b64",
        },
        {
          name: "Kenneth",
          value: "0xc6aaba4d4ede6c5cbc1bb9fd863701726ed63d2e",
        },
        {
          name: "Mario",
          value: "0x06c04f4a083611319d40ec2c10b5c46c3925830f",
        },
        {
          name: "Darius",
          value: "0x8956436ea7bb1edb3453104d3c597259f48d8839",
        },
        {
          name: "Nyrel",
          value: "0x26d252724d08a30151ab5c87bd6b4fb5eadb1500",
        },
        {
          name: "Joseph",
          value: "0xdd5f7425af6b20f46f9b86d3a6e179f7040f7978",
        },
        {
          name: "Jay",
          value: "0x32e472d6fbe850e68bdb5f91c672a297b09e9cfe",
        },
        {
          name: "Nico",
          value: "0xf03b717e06b99d5fd48c1d040c8ba787294f85f6",
        },
        {
          name: "Chester",
          value: "0x6176da21cc4c9b2c47774f982bf64e5cfd41a101",
        },
        {
          name: "Aaron",
          value: "0x7cb2e5e21f2afd8ee3d1ca2cd4564f7e986a269b",
        },
        {
          name: "Marvyn",
          value: "0x0148a79ce28d46172650d96a3546f2c286be15ea",
        },

        {
          name: "Emil",
          value: "0x0857132b88145bc4d2f95231769e9c832bb148bb",
        },

        {
          name: "Rache",
          value: "0x81ce476e78fd20c970eac2a89fe3c280eb7a8423",
        },
        {
          name: "Wykie",
          value: "0x46e4daba7da7d0b395b2f15537899adef5b5bc60",
        },
        {
          name: "Mikko",
          value: "0x6c158a56bfbb9f02353e0ef1830062b2e98b2377",
        },

        {
          name: "Ralph",
          value: "0xa7c865de2450172fcb4a95beeb642b7639f403ef",
        },
        {
          name: "Vitor",
          value: "0x0a731d744ba1f4da6c8df1cb6334aa92081ac476",
        },
        {
          name: "Joanna",
          value: "0x987ad8a72df8dd6672cf33b9d9c1abdfab830c84",
        },
        {
          name: "Aimee",
          value: "0xe2676d80b00ef758bd453c143bb543ca53fd6015",
        },
        {
          name: "Aya",
          value: "0x7bad5c65f2e2c53e65a5c32d330c070c337ce066",
        },
        {
          name: "Cheo",
          value: "0x392853d1f36b3f356cc9c4e998121b193bbbe722",
        },
        {
          name: "Ichivan",
          value: "0xc5979bc2a041e905ed49315e62c7f22875068674",
        },
        {
          name: "Joel",
          value: "0x5254c72423fcb896fb85b102b3909db36e1e2315",
        },
        {
          name: "Tristan",
          value: "0x646f8b6498f885d3df975030982bae7f2ca3e6f6",
        },
        {
          name: "Jenny",
          value: "0x4e1bf984640d6d6e4915c12e1d461d7aa1148ba6",
        },
        {
          name: "Kevin",
          value: "0x72b68d34706ee882b256b12ca86e8d6df9ef4760",
        },
        {
          name: "JC",
          value: "0xfb77145ebe6bab6a25e77567b6f6861fa60cf8c1",
        },
        {
          name: "Bren",
          value: "0xb2c43d8cc29654d5f006373cb5b940bdd25f1d77",
        },
        {
          name: "Yna",
          value: "0x21604ddd945b6af53eb66c5f3f42711f2cc6c727",
        },
        {
          name: "Jersey",
          value: "0x81b30dac5b3aff8c4de534298db206de6e6f9556",
        },

        {
          name: "Aiza ",
          value: "0xea3e579800dec907d4621d4677646dfcd43b8942",
        },
        {
          name: "Sean ",
          value: "0x5a843989e9ba70d8104fdb9178fac665d3ceb812",
        },
        {
          name: "Marc",
          value: "0x7c7f158bcc6c9d69d435a3be630cdb3d44269165",
        },
        {
          name: "Anthony",
          value: "0xf2251130f9a7bd8c90f7e460d3eebe48c099bc75",
        },
        {
          name: "RJ",
          value: "0x74318d7b0af0a073ba01e5619503d2053905fb7b",
        },
        {
          name: "Jem",
          value: "0xae80d6563291daf375e75bd88fd99b1f35227e48",
        },
        {
          name: "Nizza",
          value: "0xfa28c22cd22dfb196af2cb180d1bc002071de409",
        },
        {
          name: "Charles",
          value: "0xe627f112ff028313b208a0f01b589fe181e247ae",
        },
        {
          name: "Hans",
          value: "0x3a7604b476879ccece2d4ed0c806ce4f24fa4408",
        },
        {
          name: "Shiela",
          value: "0xd627217fd16030fc39487fa7460c7997f23bf5fa",
        },
        {
          name: "Harold",
          value: "0x9f8aa92a57824b4a36d22a6015ebe04d97c42486",
        },
        {
          name: "James",
          value: "0x6dfb4745da31111268fda153d16baed20177d752",
        },
        {
          name: "Shugi",
          value: "0x3f0b291710b989b5c924b2a1476dadca73e9a211",
        },
        {
          name: "Peewee",
          value: "0xce422261af94c9bf10b8924f4496fc4833aace59",
        },
        {
          name: "Maurel",
          value: "0x59ef85c8dc7329862ea2b04ae98241b98273cf3f",
        },
        {
          name: "Christian",
          value: "0x4c79a6d00b9677b95b943b01afc89b38110c4801",
        },
        {
          name: "Riockee",
          value: "0xb6bfe1723c9a5c11f75228109c24f173ac73dfc3",
        },
        {
          name: "Aeron",
          value: "0xfa9368217dd2fc3289a62647fed0544133232621",
        },
        {
          name: "Angela",
          value: "0x5b64137898049b0a2deeb7b1e7b2cb7f4b43d18b",
        },
        {
          name: "Dice",
          value: "0x54bdccb729c34a0eed120d351b77a895db64d3e4",
        },
        {
          name: "Jayson",
          value: "0xb2cc121c7318c525240f7b2b8210f127cbaaafd1",
        },
        {
          name: "Jhon",
          value: "0x9061bfdc1597f2032f3af9b8b75a74d8a895b789",
        },
        {
          name: "Rovic",
          value: "0x2b17b55d59b8829b45f053c68ba7c643f0fc03a5",
        },
        {
          name: "Nhel",
          value: "0x9750a33b1957ee7f3cad5b98df1f6506d1ee4622",
        },
        {
          name: "Junard",
          value: "0x949313a3002fa5eb889040df16192332ac9790c8",
        },
        {
          name: "Deejay",
          value: "0x86d0b8cafa86cc400570bd84e1b8b82c9a1e7e8c",
        },

        {
          name: "Anafloren",
          value: "0x1444efb67617562745da810d31f28e1688dce005",
        },
        {
          name: "Issa",
          value: "0xbe7adb40937467f58e9e39c55759ec4d46231e34",
        },
        {
          name: "Bryan",
          value: "0x9812cf69d48622cc52f32ff785638b372f91746c",
        },
        {
          name: "Earl",
          value: "0x41e033d6829f61bb61f5f1d992b945ee1a79021b",
        },
        {
          name: "John Rey",
          value: "0x206de7a6e73568676b56b8d95d7c711a96b2a025",
        },
        {
          name: "Regen",
          value: "0x21c5c1e95e2920bc176791caef5254f212e2561a",
        },
        {
          name: "Carlo",
          value: "0xc7584198f0c2e9926857849146296662d6221dfd",
        },
        {
          name: "JR",
          value: "0xce9bb4ab6e7591c30637f2b828174ff391b7bc9a",
        },
        {
          name: "BJ",
          value: "0xe6e8237429a8bc962007cc6334b38a500c038bcf",
        },
        {
          name: "Mervic",
          value: "0x61fc72e53928efc1ef600b0e8320d2be303a4c0d",
        },
        {
          name: "Benalisa",
          value: "0x613aeebd58dad5c22dd6ccba2bdfea0ab537d2fa",
        },
        {
          name: "Kate",
          value: "0x0763ced2fb36b9d6b7b6e9c917e6346d9036e81b",
        },
        {
          name: "Kyle",
          value: "0x5a8e290ab70cf38120cec47c7e228a63c7ccf52d",
        },
        {
          name: "Michel",
          value: "0x6d2946600111500da1222a4335bde1d539625308",
        },
        {
          name: "Jommel",
          value: "0xf8fb91f99cdfe5804d78db09c9b8d6d12cb9dd09",
        },
        {
          name: "Casper",
          value: "0xd290925515eabe6b6f2c97c7c4e0b34c8a6645c9",
        },
        {
          name: "Vitor",
          value: "0x86d681107ccffe815dd3dcab5e943f0d467bf27b",
        },
        {
          name: "Ralph",
          value: "0x93d94be389918a5d08ac267b14ee616c42f4f7c1",
        },
        {
          name: "Vitor",
          value: "0x2f7265ecc2703455c0f5f374f9a9e057b2e48da0",
        },
        {
          name: "Ralph",
          value: "0x36495f9f8aabcc44664e967b28a946922663e19d",
        },
        {
          name: "Vitor",
          value: "0xb9fce8c0388774216b4c57a3fc4d81eb6f9b6200",
        },
        {
          name: "Ralph",
          value: "0xa8053b80228743757e1bcb316a38a4de9769b15f",
        },
        {
          name: "Ralph",
          value: "0xea9a4d600b7fd9c4e83877f8c41639501e9077ea",
        },
        {
          name: "Ralph",
          value: "0xa6b22805f4f795e1df9df90c41ec089dd30f2cfa",
        },
      ],
    };
  },

  methods: {
    async getUsers() {
      let index = 0;

      for (const val of this.ronin) {
        // You can use `let` instead of `const` if you like
        await axios
          .get("https://api.axie.com.ph/get-update/" + val.value)
          .then((response) => {
            this.users[index] = response.data;
            this.users[index]["index"] = index + 1;
            this.users[index]["name"] = response.data.leaderboard.name;
            this.totalslp += response.data.slp.total;
            this.ingame += response.data.slp.total;
            this.users[index]["address"] =
              "https://marketplace.axieinfinity.com/profile/" +
              "ronin:" +
              val.value.substring(2) +
              "/axie";
            // this.users[index]["daily"] = response.data.adventureSLP;
            index++;
          })
          .catch(function(error) {
            console.error(error);
          });
      }
    },
    async getDaily() {
      let index = 0;

      for (const val of this.ronin) {
        const options = {
          method: "GET",
          url: "https://axie-infinity.p.rapidapi.com/get-daily/" + val.value,
          headers: {
            "x-rapidapi-host": "axie-infinity.p.rapidapi.com",
            "x-rapidapi-key":
              "db59161586mshe24438a1ece8b68p1cd639jsn8b84b09955fd",
          },
        };

        let responses = await axios
          .request(options)
          .then(function(response) {
            return response;
          })
          .catch(function(error) {
            console.error(error);
          });
        this.users[index]["daily"] =
          responses.data.gained_slp_response.gained_slp;

        index++;
      }
    },
    async getaxies() {
      let index = 0;
      let axieimage = [];

      for (const val of this.ronin) {
        const options = {
          method: "GET",
          url: "https://api.axie.com.ph/get-axies/" + val.value,
        };

        let responses = await axios
          .request(options)
          .then(function(response) {
            for (var val of response.data.results) {
              axieimage.push(val.image);
            }
            return axieimage;
          })
          .catch(function(error) {
            console.error(error);
          });

        this.users[index]["axies"] = responses;
        responses = [];
        axieimage = [];

        index++;
      }
    },
    dateTime(value) {
      return moment
        .unix(value)
        .add(14, "day")
        .format("MMM DD,  hh:mm a");
    },
    checkave(value) {
      var unix = moment.unix(value);
      var given = moment(unix, "YYYY-MM-DD");
      var current = moment().startOf("day");
      var diff = Math.floor(
        Math.abs(moment.duration(given.diff(current)).asDays())
      );
      return diff;
    },
  },
  async created() {
    await this.getUsers();
    // await this.getaxies();

    // await this.getDaily();
  },
};
</script>
