<template>
  <nav></nav>
</template>
); }

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>
