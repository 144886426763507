<template>
  <div
    id="map-canvas"
    class="relative w-full rounded h-600-px"
    data-lat="40.748817"
    data-lng="-73.985428"
  ></div>
</template>
<script>
export default {
  mounted() {},
};
</script>
