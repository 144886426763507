<template>
  <!-- Navbar -->
  <nav
    class="absolute w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center "
  ></nav>
  <!-- End Navbar -->
</template>

<script>
export default {
  components: {},
};
</script>
